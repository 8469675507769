$(function (){
  if ($(window).width() >= 768){
      $('.iziModal').iziModal({
          iframe: true,
          fullscreen: true,
          width: 1200,
          iframeHeight: 675,
          overlayColor: 'rgba(0, 0, 0, 0.8)',
          headerColor: '#000',
          transitionIn: 'bounceInDown',
          transitionOut: 'bounceOutDown',
          zindex: '10000'
      });
  } else {
      $('.iziModal').iziModal({
          iframe: true,
          fullscreen: true,
          width: 1000,
          iframeHeight: 300,
          overlayColor: 'rgba(0, 0, 0, 0.8)',
          headerColor: '#000',
          transitionIn: 'bounceInDown',
          transitionOut: 'bounceOutDown',
          zindex: '10000'
      });
  }
});